
import { defineComponent, PropType } from 'vue';
import { NewsData } from '../assets/Global';

export default defineComponent({
    props: {
        news: {
            required: true,
            type: Array as PropType<NewsData[]>
        }
    }
})
