
import { defineComponent } from 'vue';
import IFrameLink from '@/assets/Global';
import ProjectList from './components/ProjectList.vue';
import NewsList from './components/NewsList.vue';
import data from './assets/data.json';

export default defineComponent({
	name: 'App',
	components: {
		ProjectList,
		NewsList
	},
	methods: {
		CloseIFrame(): undefined {
			this.IFrameLink = "";
			return;
		}
	},
	data() {
		return { data, IFrameLink }
	}
});
