
import IFrameLink from '@/assets/Global';
import { defineComponent, PropType } from 'vue';
import { Project } from '../assets/Global';

export default defineComponent({
    props: {
        projects: {
            required: true,
            type: Array as PropType<Project[]>
        }
    },
    methods: {
        RunIFrame(run?: string) : undefined {
            if (!run) return;
            IFrameLink.value = run;
        },
        GetImgUrl(img?: string): string {
            if (img && img != '') return require(`../assets/images/${img}.png`)
            return require(`../assets/images/Error.png`)
        }
    }
})
