import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70799ae5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proItemList" }
const _hoisted_2 = { class: "proItemTitle" }
const _hoisted_3 = { class: "proItemDate" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news.slice(0, 3), (ne) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "proItem",
        key: ne.title
      }, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(ne.title), 1),
        _createElementVNode("h4", _hoisted_3, "(" + _toDisplayString(ne.date) + ")", 1),
        _createElementVNode("span", {
          class: "proItemDescription",
          innerHTML: ne.description
        }, null, 8, _hoisted_4)
      ]))
    }), 128))
  ]))
}