import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d4f3864"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proItemList" }
const _hoisted_2 = { class: "proItemTitle" }
const _hoisted_3 = { class: "proItemCenter" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "proItemLinks" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (proj) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "proItem",
        key: proj.title
      }, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(proj.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "proItemDescription",
            innerHTML: proj.description
          }, null, 8, _hoisted_4),
          _createElementVNode("img", {
            src: _ctx.GetImgUrl((proj.image ?? {}).img),
            alt: "Game",
            class: "proItemImage"
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(proj.links, (link) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: link.text
            }, [
              (link.text != 'Play')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "proItemLink",
                    href: link.link,
                    target: "_blank"
                  }, _toDisplayString(link.text), 9, _hoisted_7))
                : _createCommentVNode("", true),
              (link.text == 'Play')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "proItemLink",
                    href: "#run",
                    onClick: ($event: any) => (_ctx.RunIFrame(link.run))
                  }, _toDisplayString(link.text), 9, _hoisted_8))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}